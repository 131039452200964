<template>
  <v-col cols="12">
    <v-data-table
      v-if="!formulario"
      :headers="headers"
      :items="items"
      :pagination.sync="paginacao"
      :server-items-length="totalRegistros"
      :loading="loading"
    >
      <template slot="no-data">
        <v-btn
          color="primary"
          @click.stop="novo()"
        >
          Novo
        </v-btn>
      </template>
      <template
        v-if="items && items.length"
        slot="actions-append"
      >
        <v-btn
          color="primary"
          @click.stop="novo()"
        >
          <v-icon>mdi-plus</v-icon>
          Novo
        </v-btn>
      </template>
      <template
        slot="headerCell"
        slot-scope="{ header }"
      >
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template
        slot="items"
        slot-scope="props"
      >
        <td
          class="justify-left btnEditar cursorPointer"
          @click="editar(props.item)"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
          {{ logradouro(props.item) }}
        </td>
        <td class="justify-center">
          {{ props.item.tipoEndereco }}
        </td>
      </template>
    </v-data-table>
    <v-form
      v-else
      xs12
    >
      <v-col cols="12">
        <v-text-field
          v-model="objEditar.cep"
          mask="#####-###"
          label="CEP"
        />
        <v-btn
          :loading="loadingEndereco"
          :disabled="loadingEndereco"
          color="info"
          @click="buscaEndereco"
        >
          <v-icon>mdi mdi-map-search</v-icon>
          Buscar Endereço
          <div
            slot="loader"
            style="display:flex;"
          >
            <span class="custom-loader">
              <v-icon light>mdi-loading</v-icon>
            </span>
            <span>Buscando</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="autoCompleteModel"
          :items="autoCompleteItens"
          :loading="autoCompleteLoading"
          :search-input.sync="autoCompleteCampoBusca"
          :filter="filterAc"
          color="white"
          hide-no-data
          hide-selected
          item-text="cidadeEstado"
          item-value="idCidade"
          label="Estado / Cidade"
          placeholder="Digite alguma palavra para realizar a busca"
          prepend-icon="mdi-database-search"
          return-object
        />
      </v-col>
      <v-col cols="7">
        <v-text-field
          v-model="objEditar.rua"
          :rules="[required]"
          required
          prepend-icon="mdi mdi-apple-icloud"
          label="Rua"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="objEditar.numero"
          label="Número"
          mask="#####"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="objEditar.complemento"
          label="Complemento"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="objEditar.bairro"
          label="Bairro"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="objEditar.tipoEndereco"
          label="Tipo de Endereço"
        />
      </v-col>
      <v-alert
        v-model="alertValidacao"
        dismissible
        type="error"
        transition="scale-transition"
      >
        {{ error }}
      </v-alert>
      <v-col cols="12">
        <v-card-actions>
          <v-btn
            v-if="isEditting"
            color="error"
            class="hidden-sm-and-down"
            @click="deleteEvent()"
          >
            delete
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-btn>
          <!-- <v-btn flat color="primary">More</v-btn> -->
          <v-spacer />
          <v-btn
            color="tertiary"
            class="hidden-sm-and-down"
            @click="closeDialog()"
          >
            Cancelar
            <v-icon dark>
              mdi mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="hidden-sm-and-down"
            @click="saveEvent()"
          >
            Salvar
            <v-icon dark>
              mdi mdi-content-save
            </v-icon>
          </v-btn>
          <v-menu
            offset-y
            class="hidden-md-and-up"
          >
            <v-btn
              slot="activator"
              color="primary"
              dark
            >
              <v-icon dard>
                mdi mdi-gesture-tap
              </v-icon>
              Ações
            </v-btn>
            <v-list>
              <v-list-item @click="saveEvent()">
                <v-list-item-action>
                  <v-icon color="primary">
                    mdi mdi-content-save
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Salvar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="closeDialog()">
                <v-list-item-action>
                  <v-icon color="tertiary">
                    mdi mdi-close
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Cancelar</v-list-item-title>
              </v-list-item>
              <v-divider v-if="isEditting" />
              <v-divider v-if="isEditting" />
              <v-list-item
                v-if="isEditting"
                @click="deleteEvent()"
              >
                <v-list-item-action>
                  <v-icon color="error">
                    mdi-close
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-col>
    </v-form>
  </v-col>
</template>

<script>

  import RegionalService from '@/services/RegionalService'
  import EnderecosService from '@/services/EnderecosService'

  export default {

    props: {
      idPessoa: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        alertValidacao: false,
        autoCompleteModel: null,
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',
        loadingEndereco: false,
        headers: [
          {
            text: 'Endereco',
            value: 'rua'
          },
          {
            text: 'Tipo de Endereço',
            value: 'tipoEndereco'
          }
        ],
        items: [],
        paginacao: null,
        loading: false,
        totalRegistros: 0,
        objPadrao: {
          rua: '',
          numero: 0,
          complemento: '',
          bairro: '',
          cidade: null,
          cep: '',
          tipoEndereco: 0
        },
        objEditar: {
          rua: '',
          numero: 0,
          complemento: '',
          bairro: '',
          cidade: '',
          cep: '',
          tipoEndereco: 0
        },
        isEditting: false,
        error: null,
        required: value => !!value || 'Campo Obrigatório',
        formulario: false
      }
    },
    watch: {
      idPessoa (valor) {
        if (valor > 0) {
          this.buscar()
        }
      },
      autoCompleteCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaCidades(val)
      },
      autoCompleteModel (val) {
        if (val === null) {
          return
        }
        this.objEditar.cidade = val.denCidade
      }

    },
    methods: {
      novo () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.formulario = true
        this.editedIndex = -1
      },

      logradouro (endereco) {
        let retorno = endereco.rua + ',' + endereco.numero
        if (endereco.bairro) {
          retorno += ' ' + endereco.bairro
        }
        if (endereco.Cidade) {
          retorno += ' ' + endereco.Cidade.cidadeEstado
        }
        if (endereco.cep) {
          retorno += ' ' + endereco.cep
        }
        return retorno
      },
      buscaEndereco () {
        this.loadingEndereco = true
        this.alertValidacao = false
        this.error = 'null'
        let cep = this.objEditar.cep
        if (!cep || cep.length < 8) {
          this.alertValidacao = true
          this.error = 'Campo cep não preenchido corretamente'
          this.loadingEndereco = false
          return
        }
        cep = cep.replace('-', '')
        if (cep.length !== 8) {
          this.alertValidacao = true
          this.error = 'Campo cep não preenchido corretamente'
          this.loadingEndereco = false
          return
        }
        RegionalService.buscaEnderecoPorCEP(cep)
          .then(resultado => {
            if (!resultado || resultado.data.erro) {
              this.alertValidacao = true
              this.error = 'Não foi encontrado algum endereço para este CEP'
              this.objEditar.cidade = ''
              this.objEditar.rua = ''
              this.objEditar.bairro = ''
              return
            }

            this.objEditar.rua = resultado.data.logradouro
            this.objEditar.bairro = resultado.data.bairro

            const estadoCidade = resultado.data.localidade + ' ' + resultado.data.uf

            RegionalService.buscaCidadesPorParam(estadoCidade)
              .then(cidades => {
                if (cidades && cidades.data && cidades.data.length === 1) {
                  this.autoCompleteItens = cidades.data
                  this.autoCompleteModel = cidades.data[0]
                }
              })
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: '' + error
            })
          })
          .finally(() => {
            this.loadingEndereco = false
          })
      },
      editar (endereco) {
        this.editedIndex = this.items.indexOf(endereco)
        this.objEditar = Object.assign({}, endereco)
        this.autoCompleteModel = this.objEditar.Cidade
        this.autoCompleteItens = [this.objEditar.Cidade]
        this.formulario = true
        this.isEditting = true
      },

      closeDialog () {
        this.formulario = false
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
        this.autoCompleteModel = null
        this.autoCompleteItens = []
      },
      saveEvent () {
        this.formulario = false
        this.isEditting = false

        this.objEditar.CidadeId = this.autoCompleteModel.id
        this.objEditar.PessoaId = this.idPessoa

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialog()
      },

      deleteEvent () {
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoTitulo: 'Atenção',
          dialogoDescricao: 'Tens certeza que deseja remover este endereço?',
          funcaoRetorno: this.remover
        })
      },

      remover () {
        const endereco = this.objEditar
        try {
          EnderecosService.delete(endereco).then(response => {
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: 'Sucesso',
              dialogoDescricao: 'Endereço removido com sucesso'
            })
            this.closeDialog()
            this.buscar()
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Ocorreu um erro ao remover um endereço:\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover o endereço:\n ->' + error
          })
        }
      },

      buscaCidades (param) {
        this.autoCompleteLoading = true
        RegionalService.buscaCidadesPorParam(param)
          .then(cidades => {
            this.autoCompleteItens = cidades.data
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca das cidades ' + error
            })
          })
          .finally(() => {
            this.autoCompleteLoading = false
          })
      },
      filterAc (item, queryText, itemText) {
        return true
      },
      inserir (registro) {
        try {
          EnderecosService.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Endereço salvo com sucesso'
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: '' + error
          })
        }
      },
      atualizar (registro) {
        try {
          EnderecosService.update(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Endereço atualizado com sucesso'
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Erro ao atualizar o Endereço ' + error
          })
        }
      },
      buscar () {
        try {
          const param = {
            pagination: this.pagination,
            idPessoa: this.idPessoa
          }
          this.loading = true
          if (!this.idPessoa) {
            return
          }

          EnderecosService.findAll(param)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
                return
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
                return
              }
              this.items = response.data.rows
              this.totalRegistros = response.data.count
              this.loading = false
            })
            .catch(err => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Erro ao comunicar com o servidor, avise os Administradores -> ' + err
              }
              )
            })
            .finally(() => {
              this.loading = false
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Erro na busca de Endereços' + error
          })
          this.loading = false
        }
      }
    }
  }
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
